import React, { useEffect, useMemo, useState } from 'react';
import { FaTimes } from 'react-icons/fa';

// import logo from '../images/logo_wit.webp';
import logoVl from '../images/logo-sin-vl.webp';
import logoNl from '../images/stop-it-now-logo.svg';

export default function Footer({ popups, isHomepage }) {
  const [popupState, setPopupState] = useState('hidden'); // 'hidden', 'entering', 'visible', 'exiting'
  const [hidePopup, setHidePopup] = useState(false); // 'hidden', 'entering', 'visible', 'exiting'

  const popupToShow = useMemo(() => {
    if (isHomepage) {
      // find popus where today is after or equal to zichtbaar_vanaf and before or equal to zichtbaar_tot
      const popupsToShow = popups.filter((popup) => {
        const popupVanaf = new Date(popup.zichtbaar_vanaf);
        const popupTot = new Date(popup.zichtbaar_tot);
        const today = new Date();
        return popupVanaf <= today && popupTot >= today;
      });
      return popupsToShow[0];
    }
  }, [popups, isHomepage]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY;
      const pageHeight = document.documentElement.offsetHeight;
      const scrollThreshold = pageHeight - 200; // 200px from the bottom

      if (scrollPosition > scrollThreshold && popupState === 'hidden') {
        setPopupState('entering');
      } else if (scrollPosition <= scrollThreshold && popupState === 'visible') {
        setPopupState('exiting');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [popupState]);

  useEffect(() => {
    let timeoutId;
    if (popupState === 'entering') {
      timeoutId = setTimeout(() => setPopupState('visible'), 10);
    } else if (popupState === 'exiting') {
      timeoutId = setTimeout(() => setPopupState('hidden'), 300); // Match this with the CSS transition time
    }
    return () => clearTimeout(timeoutId);
  }, [popupState]);

  const handleClose = () => {
    setPopupState('exiting');
    setHidePopup(true);
  };

  return (
    <footer className="global-footer d-print-none">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-3 text-left">
            <p className="footer-cta text-left">
              Heb je vragen, ben je op zoek naar hulp of advies of wil je feedback geven?
              <br />
              <br />
              Neem contact op met Stop it Now Vlaanderen of Stop it Now Nederland.
              <br />
              <br />
              <strong>Bel ons anoniem en gratis of mail ons</strong>
            </p>
          </div>
          <div className="col-md-4 text-center">
            <a href="https://stopitnow.be" target="_blank" rel="noreferrer">
              <img alt="Logo Nederland" className="logo-footer" src={logoVl} />
            </a>
            <p>
              <strong>Vlaanderen</strong>
            </p>
            <p className="footer-contact">
              <a href="tel:080020050" target="_blank" rel="noreferrer">
                0800 200 50
              </a>
              <br />
              <a href="mailto:vragen@stopitnow.be" target="_blank" rel="noreferrer">
                vragen@stopitnow.be
              </a>
            </p>
          </div>
          <div className="col-md-4 text-center">
            <a href="https://stopitnow.nl" target="_blank" rel="noreferrer">
              <img alt="Logo Nederland" className="logo-footer" src={logoNl} />
            </a>
            <p>
              <strong>Nederland</strong>
            </p>
            <p className="footer-contact">
              <a href="tel:08002666436" target="_blank" rel="noreferrer">
                0800 266 64 36
              </a>
              <br />
              <a href="https://stopitnow.nl/contact" target="_blank" rel="noreferrer">
                Contactgegevens
              </a>
              <br />
              <a href="https://chat.stopitnow.nl/chat" target="_blank" rel="noreferrer">
                Chat met ons
              </a>
            </p>
          </div>
        </div>
      </div>
      {popupState !== 'hidden' && popupToShow && hidePopup === false && (
        <div className={`cta-popup-box ${popupState}`}>
          <div className="cta-popup-header">
            <button className="close-popup" onClick={handleClose}>
              <FaTimes />
            </button>
          </div>
          <div className="cta-popup-body wysiwyg">
            <h5 className="h2 title">{popupToShow.title}</h5>
            <div
              className="text-wrapper"
              dangerouslySetInnerHTML={{ __html: popupToShow.content }}
            ></div>
          </div>
          <div className="cta-popup-footer">
            <a
              className="btn btn-tertiary"
              href={popupToShow.cta_link_be}
              target="_blank"
              rel="noreferrer"
            >
              {popupToShow.cta_tekst_be}
            </a>
          </div>
        </div>
      )}
    </footer>
  );
}
